import axios from 'axios';
// import { useCookies } from "vue3-cookies";
// const { cookies } = useCookies();

export const $axios = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL_API, //`http://127.0.0.1/hostel/backend/api/`,
    // baseURL: `http://hostel.cmhlmc.com/backend/api/`,
    headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

// export const $axios = $ax.interceptors.response.use(function (response) {
//         console.log(response);
//         // Any status code within the range of 2xx cause this function to trigger
//         // Do something with response data
//         return response;
//     }, function (error) {
//         console.log(error);
//         alert("errr");
//         // Any status codes outside the range of 2xx cause this function to trigger
//         // Do something with response error
//         return Promise.reject(error);
// });