import { createRouter, createWebHistory } from 'vue-router';
// console.log(this.menu);
const routes = [
    {
        path: '/roles',
        name: 'roles',
        component: () => import('./pages/AccessManagement/Roles.vue')
    }, {
        path: '/leave-applications',
        name: 'leave-applications',
        component: () => import('./pages/LeaveApplications/LeaveApplications.vue'),
        meta: {
            breadcrumb: [{ parent: 'Students', label: 'Pending Leave Applications' }],
        }
    }, {
        path: '/leave-applications-all',
        name: 'leave-applications-all',
        component: () => import('./pages/LeaveApplications/LeaveApplicationsAll.vue'),
        meta: {
            breadcrumb: [{ parent: 'Students', label: 'All Leave Applications' }],
        }
    }, {
        path: '/hostel-students',
        name: 'hostel-students',
        component: () => import('./pages/Students/Students.vue'),
        meta: {
            breadcrumb: [{ parent: 'Students', label: 'Hostel Students' }],
        }
    }, {
        path: '/expected-arrive',
        name: 'expected-arrive',
        component: () => import('./pages/StudentExpectedArrive/Index.vue'),
        meta: {
            breadcrumb: [{ parent: 'Students', label: 'Expected Arrivals' }],
        }
    }
    , {
        path: '/leave-applications-ho-core',
        name: 'leave-applications-ho-core',
        component: () => import('./pages/LeaveApplicationsHoCore/LeaveApplicationsHoCore.vue'),
        meta: {
            breadcrumb: [{ parent: 'HOs & Corps', label: 'Pending Leave Applications' }],
        }
    }, {
        path: '/leave-applications-ho-core-all',
        name: 'leave-applications-ho-core-all',
        component: () => import('./pages/LeaveApplicationsHoCore/LeaveApplicationsHoCoreAll.vue'),
        meta: {
            breadcrumb: [{ parent: 'HOs & Corps', label: 'All Leave Applications' }],
        }
    }, {
        path: '/ho-core',
        name: 'ho-core',
        component: () => import('./pages/HoCore/HoCore.vue'),
        meta: {
            breadcrumb: [{ parent: 'HOs & Corps', label: 'Hostel HOs & Corps' }],
        }
    }, {
        path: '/generate-report',
        name: 'generate-report',
        component: () => import('./pages/Reports/GenerateReports.vue'),
        meta: {
            breadcrumb: [{ parent: 'Reports', label: 'Generate Reports' }],
        }
    },
    {
        path: '/expected-arrive-ho-core',
        name: 'expected-arrive-ho-core',
        component: () => import('./pages/HoCoreExpectedArrive/Index.vue'),
        meta: {
            breadcrumb: [{ parent: 'HOs & Corps', label: 'Expected Arrivals' }],
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./pages/Dashboards/Dashboard.vue')
    },
    {
        name: 'dashboardRoute',
        path: '/',
        component: () => import('./pages/Dashboards/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'Home', label: 'Dashboard' }],
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('./pages/Logout.vue'),
        meta: {
            breadcrumb: [{ parent: 'Access Management', label: 'Logout' }],
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./pages/Register.vue')
    },

    {
        name: 'settings',
        path: '/settings',
        component: () => import('./pages/Settings.vue'),
        meta: {
            breadcrumb: [{ parent: 'Profile Settings', label: 'Settings' }],
        }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    }
});

export default router;