<template>
	<div class="layout-topbar shadow-4">
		<div class="layout-topbar-left">
			<router-link class="layout-topbar-logo" to="/">  
			<img :src="filesUrl+'logo/logo.png'"  alt="logo" style="height: 3.50rem">   
			<div class="ml-2"> <h3 class="logo-text">CMHLMC</h3></div>
			
			</router-link> 
			<a class="layout-menu-button shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
				<i class="pi pi-chevron-right"></i>
			</a> 
			<a class="layout-topbar-mobile-button p-ripple" @click="onTopbarMobileButtonClick($event)" v-ripple>
				<i class="pi pi-ellipsis-v fs-large"></i>
			</a>
		</div>

		<div class="layout-topbar-right" :class="{'layout-topbar-mobile-active': mobileTopbarActive}">
			<div class="layout-topbar-actions-left"> </div> 
			<div class="layout-topbar-actions-right">
				<ul class="layout-topbar-items">  
					 
					<li class="layout-topbar-item">
						<a class="layout-topbar-action flex flex-row justify-content-center align-items-center px-2 rounded-circle p-ripple" @click="onTopbarItemClick($event, 'profile')" v-ripple>
							<!-- <img src="demo/images/avatar/amyelsner.png" alt="avatar" style="width: 32px; height: 32px;"> -->
							<img  v-if="user.profile_pic" :alt="user.name" :src="filesUrl+'users/'+user.profile_pic" style="width: 32px; height: 32px;">
        					<img  v-else :alt="user.name" :src="filesUrl+'defaults/default.png'" style="width: 32px; height: 32px;">
						</a> 
						<transition name="layout-topbar-action-panel">
							<ul class="layout-topbar-action-panel shadow-6" v-show="activeTopbarItem === 'profile'"> 
								<li class="layout-topbar-action-item">
									<router-link class="flex flex-row align-items-center p-ripple" to="/logout"> 
										<i class="pi pi-power-off" :class="{'mr-2': !isRTL, 'ml-2': isRTL}"></i>
										<span>Logout</span>
								 	</router-link>
								</li> 
							</ul>
						</transition>
					</li>  
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
		data() {
			return {
				searchText: '', 
				user:'', 
			}
		},
		props: {
			horizontal: {
				type: Boolean,
				default: false
			},
			topbarMenuActive: {
				type: Boolean,
				default: false
			},
			activeTopbarItem: String,
			mobileTopbarActive: Boolean,
			searchActive: Boolean
		},
		mounted(){ 
            this.user = JSON.parse(localStorage.getItem('user'));   
        },
		methods: {
			 
			onSearchContainerClick(event) {
				this.$emit("search-click", event);
			},
			changeSearchActive(event) {
				this.$emit('search-toggle', event);
			},
			onMenuButtonClick(event) {
				this.$emit('menubutton-click', event);
			},
			onTopbarMenuButtonClick(event) {
				this.$emit('topbar-menubutton-click', event);
			},
			onTopbarItemClick(event, item) {
				if(item === 'search') {
					this.$emit('search-toggle', event);
				} 
				this.$emit('topbaritem-click', {originalEvent: event, item: item});
			},
			onTopbarMobileButtonClick(event) {
				this.$emit('topbar-mobileactive', event);
			},
			onRightPanelButtonClick(event) {
				this.$emit('rightpanel-button-click', event);
			},
			onSearchKeydown(event) { 
				if (event.keyCode == 13) {
					this.$emit('search-toggle', event);
				}
			},
			onEnter() {
				if (this.$refs.searchInput) {
					this.$refs.searchInput.$el.focus();
				}
			}
		},
		computed: {
			topbarItemsClass() {
				return ['topbar-items animated fadeInDown', {
					'topbar-items-visible': this.topbarMenuActive
				}];
			},
			isRTL() {
				return this.$appState.RTL;
			}
		}
	}
</script>
